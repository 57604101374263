import CarouselComponent from '@/components/carousel/carousel.component';
import FormComponent from '@/components/fields/form/form.component';
import InputComponent from '@/components/fields/input/input.component';
import TextareaComponent from '@/components/fields/textarea/textarea.component';
import GooglemapComponent from '@/components/googlemap/googlemap.component';
import ParallaxComponent from '@/components/parallax/parallax.component';
// import PopupComponent from '@/components/popup/popup.component';
import { Component, Vue } from 'vue-property-decorator';
// tslint:disable-next-line: no-var-requires
const simpleParallax = require('simple-parallax-js');

@Component({
    components: {
        CarouselComponent,
        GooglemapComponent,
        // PopupComponent,
        FormComponent,
        InputComponent,
        TextareaComponent,
        ParallaxComponent,
    },
})
export default class HomePage extends Vue {

    private name: string = '';
    private email: string = '';
    private phone: string = '';
    private message: string = '';

    private location = {
        LATITUDE: 52.344300,
        LONGITUDE: 4.819430,
    };

    private popupVisible: boolean = false;
    protected mounted() {

        const images = document.querySelectorAll('parallax');
        new simpleParallax(images, {
            delay: 0,
            orientation: 'down',
            scale: 1.3,
            overfow: true
        });
    }
 
    private onServiceClick() {
        this.popupVisible = true;
    }

    private onContactSubmitClick() {
        // this.$notify('Uw bericht is verzonden. Er zal zo spoedig mogelijk contact opgenomen worden.');
        const loginForm = this.$refs.contactFrom as FormComponent;
        if (!loginForm.validate()) {
            return;
        }

        window.open(`mailto:info@roeldejonge.com?subject=Mail via de website van ${this.name}&body=${this.message}`);
        this.name = '';
        this.email = '';
        this.phone = '';
        this.message = '';
    }

}
