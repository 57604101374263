import FooterComponent from '@/components/footer/footer.component';
import NavigationComponent from '@/components/navigation/navigation.component';
import { Component, Vue } from 'vue-property-decorator';
import { RouteName } from './router/routes';

@Component({
  components: {
    NavigationComponent,
    FooterComponent,
  },
})
export default class App extends Vue {

    protected mounted() {
        this.scrollOnInitialLoad();
    }

    private async scrollOnInitialLoad() {
        const page = document.getElementById(this.$route.name as string) as HTMLElement;
        await this.$nextTick();
        setTimeout(() => {
            const navHeight = document.getElementsByClassName('navigation-component')[0].clientHeight;
            window.scrollTo(0, page.offsetTop - navHeight);
        }, 300);
    }

    private onScroll() {
        if (this.$store.getters.isRouting) {
            return;
        }
        
        this.$store.commit('isScrolling', true);

        const scrollableRoutes = [
            RouteName.HOME,
            RouteName.ABOUT,
            RouteName.SERVICES,
            RouteName.EXPERIENCES,
            RouteName.CONTACT,
        ];
        
        for (const route of scrollableRoutes) {
            // If scrolly hit the route change route.
            this.routeScrollHelper(route);
        }

        this.$store.commit('isScrolling', false);
    }

    private routeScrollHelper(route: RouteName) {
        const scrollPosition = window.scrollY;

        const el = document.querySelector(`#${route}`) as HTMLElement;
        const bodyRect = document.body.getBoundingClientRect();
        const elemRect = el.getBoundingClientRect();
        
        const elFrom   = elemRect.top - bodyRect.top;
        const elTo = el.clientHeight + elFrom;
        
        if (scrollPosition > elFrom && scrollPosition < elTo && this.$route.name !== route) {
            this.$router.push({ name: route });
        }
    }
}
