import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class HamburgermenuComponent extends Vue {

    private isOpen: boolean = false;

    private onMenuClick() {
        this.$emit('onMenuClick', !this.isOpen);
        this.isOpen = !this.isOpen;
    }
    private onOutsideMenuClick() {
        this.$emit('onMenuClick', false);
        this.isOpen = false;
    }

}