import { Component, Prop } from 'vue-property-decorator';
import { FieldBase } from '../field.base';

@Component
export default class TextareaComponent extends FieldBase {

    @Prop() private prefix!: string;
    /**
     * Emits the value to the v-model prop
     */
    private setValue(input: any): void {
        this.$emit('input', this.value);
    }

    private get inputValue(): string {
        return this.value;
    }
}
