import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { RegistrableInject } from './registerable';

@Component({ inheritAttrs: false, mixins: [RegistrableInject('form')] })
export class FieldBase extends Vue {

    public get dirty(): boolean {
        return (this.initialValue !== this.value);
    }

    public get valid(): boolean {
        return !this.errors.length;
    }

    protected get inputListeners() {
        const vm = this;
        // `Object.assign` merges objects together to form a new object
        return Object.assign({},
            // We add all the listeners from the parent
            this.$listeners,
            // Then we can add custom listeners or override the
            // behavior of some listeners.
            {
                // This ensures that the component works with v-model
                input(event: any) {
                    vm.$emit('input', event.target.value);
                },
            },
        );
    }

    /**
     * TODO: Check if there are labels with the same name.
     *
     * Should only be used by a :for and a :id binding.
     */
    private get fieldId(): string {
        if (!this.label) {
            return '';
        }

        return 'field-' + this.label.toLocaleLowerCase().trim();
    }

    @Prop() public value!: any; // Used as a v-model prop
    public initialValue: any = null;
    @Prop() private label!: string;
    @Prop() private required!: boolean;
    @Prop() private rules!: any[]; // Validation rules

    private visited: boolean = false;
    private errors: string[] = [];

    @Watch('value')
    @Watch('visited')
    public validate(forced = false): boolean {
        if (!this.visited && !forced) {
            return false;
        }

        // Dont validate if not required or no validation rules
        if (!this.rules && !this.required) {
            return true;
        }

        // If label excist wrap it with a '
        this.errors = [];

        if (!this.value && this.value !== 0) {
            const messagePrefix = (this.label) ? `*Veld '${this.label}'` : '*Dit veld';
            this.errors.push(`${messagePrefix} is verplicht.`);
            return false;
        }

        return true;
    }

    public resetValidation() {
        this.errors = [];
        this.visited = false;
        this.initialValue = this.value;
    }

    protected created() {
        // Set the initial value for dirty checking
        this.initialValue = this.value;

        // Register field so we can use them in the form component
        const me = this as any;
        // tslint:disable-next-line: no-unused-expression
        me.form && me.form.register(this);
    }

    protected beforeDestroy() {
        const me = this as any;
        // tslint:disable-next-line: no-unused-expression
        me.form && me.form.unregister(this);
    }
}
