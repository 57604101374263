import { Component, Vue, Watch } from 'vue-property-decorator';

@Component
export default class CarouselComponent extends Vue {
    private activeSlide = 1;
    private slideCount: any = 0;

    protected mounted() {
        this.calculateSlideCount();
        this.onSlideChange();
    }

    private calculateSlideCount() {
        const defaultSlot = this.$slots.default as any[];
        this.slideCount = defaultSlot.length;
    }

    private onPageClick(slide: number) {
        this.activeSlide = slide;
    }

    @Watch('activeSlide')
    private onSlideChange() {
        const defaultSlot = this.$slots.default as any[];

        for (const slot of defaultSlot) {
            slot.elm.classList.remove('is-active');
        }

        defaultSlot[this.activeSlide - 1].elm.classList.add('is-active');
    }
}
