import Vue from 'vue';
import Router from 'vue-router';
import { pageTitleMiddelware } from './middleware/pagetitle.middelware';
import { scrollMiddleware } from './middleware/scroll.middelware';
import { RouteName, routes } from './routes';

Vue.use(Router);

const router = new Router({
    // mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (to.meta.animation) {
            return;
        }
        
        return new Promise((resolve, reject) => {
            if (savedPosition) {
                resolve(savedPosition);
            } else {
                window.scrollTo(0, 0);
                resolve({ x: 0, y: 0 });
            }
        });
    }     
});

router.beforeEach((to, from, next) => {

    // if (to.name === RouteName.PREVIEW) {
    //     localStorage.setItem('PREVIEW', 'true');
    //     next({ name: RouteName.HOME });
    //     return;
    // }

    // if (to.name !== RouteName.MAINTENANCE && !JSON.parse(localStorage.getItem('PREVIEW') as any)) {
    //     next({ name: RouteName.MAINTENANCE });
    //     return;
    // }

    pageTitleMiddelware(to, from, next);
    scrollMiddleware(to, from, next);
});

export default router;
