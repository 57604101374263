import { Component, Vue } from 'vue-property-decorator';

@Component
export default class ParallaxComponent extends Vue {
    protected mounted() {
        // const scrolled = window.pageYOffset;
        // const background = document.querySelector('bg') as any;

        // background.style.top = `-${(scrolled * 0.2)}px`;
    }
}
