import HomePage from '@/pages/home/home.page';
import Vue from 'vue';

export enum RouteName {
    HOME = 'home',
    ABOUT = 'about',
    SERVICES = 'services',
    EXPERIENCES = 'experiences',
    CONTACT = 'contact',
    PRIVACY_STATEMENT = 'privacy_statement',
    MAINTENANCE = 'maintenance',
    PREVIEW = 'preview'
}

// Adds the route name enum to the global prototype, so you could use is like: this.$routeName.
Vue.prototype.$routeName = RouteName;

// Always use lazy loading on route level.
export const routes = [
    {
        path: '/',
        name: RouteName.HOME,
        component: HomePage,
        meta: {
            title: 'Home',
            requiresAuth: false,
            animation: true
        },
    },
    {
        path: '/about',
        name: RouteName.ABOUT,
        component: HomePage,
        meta: {
            title: 'Over mij',
            requiresAuth: false,
            animation: true
        },
    },
    {
        path: '/services',
        name: RouteName.SERVICES,
        component: HomePage,
        meta: {
            title: 'Diensten',
            requiresAuth: false,
            animation: true
        },
    },
    {
        path: '/customers',
        name: RouteName.EXPERIENCES,
        component: HomePage,
        meta: {
            title: 'Tevreden klanten',
            requiresAuth: false,
            animation: true
        },
    },
    {
        path: '/contact',
        name: RouteName.CONTACT,
        component: HomePage,
        meta: {
            title: 'Contact',
            requiresAuth: false,
            animation: true
        },
    },
    {
        path: '/privacy-statement',
        name: RouteName.PRIVACY_STATEMENT,
        meta: {
            title: 'Privacy Verklaring',
            requiresAuth: false,
            animation: false
        },
        component: () => import(
            /* webpackChunkName: "privacy-statement" */ '@/pages/privacy-statement/privacy-statement.page'
        ),
    },
    {
        path: '/maintenance',
        name: RouteName.MAINTENANCE,
        meta: {
            title: 'Onderhoud',
            requiresAuth: false,
            animation: false
        },
        component: () => import(
            /* webpackChunkName: "maintenance" */ '@/pages/maintenance-page/maintenance.page'
        ),
    },
    { 
        name: RouteName.PREVIEW,
        path: '/preview',
        component: HomePage,
        meta: {
            title: 'Home',
            requiresAuth: false,
            animation: true
        }
    }
];