import store from '@/store/store';
import '@/styles/main.scss';
import '@/utils/components';
import '@/utils/directives';
import '@/utils/filters';
import '@/utils/serviceworker';
import Vue from 'vue';
import App from './app';
import router from './router/router';

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
