import store from '@/store/store';
import { Route } from 'vue-router';

export const scrollMiddleware = (to: Route, from: Route, next: () => void) => {
    if (!to.meta.animation) {
        return;
    }

    store.commit('isRouting', true);
    
    if (store.state.isScrolling)
    {        
        store.commit('isRouting', false);
        next();
        return;
    }

    const page = document.getElementById(to.name as string);
    if (page) {
        const navHeight = document.getElementsByClassName('navigation-component')[0].clientHeight;
        scrollTo(page.offsetTop - navHeight, () => {
            store.commit('isRouting', false);
        });
    } else {
        store.commit('isRouting', false);
    }

    next();
};

/**
 * Native scrollTo with callback
 * @param offset - offset to scroll to
 * @param callback - callback function
 */
function scrollTo(offset: number, callback: () => void) {
    const onScroll = () => {
        const scrollTop = (window as any).scrollTop || window.pageYOffset;

        if (scrollTop === offset) {
            window.removeEventListener('scroll', onScroll);
            callback();
        }
    };
    window.addEventListener('scroll', onScroll);
    onScroll();
    window.scrollTo({
        top: offset,
        behavior: 'smooth'
    });
}
