import './form.component.scss';

import { Component, Vue } from 'vue-property-decorator';
import { FieldBase } from '../field.base';
import { RegistrableProvide } from '../registerable';

@Component({ mixins: [RegistrableProvide('form')] })
export default class FormComponent extends Vue  {

    // @Prop() private value: boolean; // Dirty checking hack..

    private fields: FieldBase[] = [];

    public register(field: FieldBase) {
        this.fields.push(field);
        // field.$watch('dirty', dirty => {
        //     console.log(dirty);
        // });

        // field.$watch('valid', valid => {
        //     console.log(valid);
        // });
    }

    public unregister() {
        // TODO: Implement unregister

        // const found = this.inputs.find(i => i._uid === input._uid)

        // if (!found) return

        // const unwatch = this.watchers.find(i => i._uid === found._uid)
        // unwatch.valid && unwatch.valid()
        // unwatch.shouldValidate && unwatch.shouldValidate()

        // this.watchers = this.watchers.filter(i => i._uid !== found._uid)
        // this.inputs = this.inputs.filter(i => i._uid !== found._uid)
        // this.$delete(this.errorBag, found._uid)
    }

    public dirty(): boolean {
        return !!this.fields.filter((fields) => fields.dirty).length;
    }

    public validate(): boolean {
        return !this.fields.filter((field) => !field.validate(true)).length;
    }

    public resetValidation(): void {
        this.fields.forEach((field) => field.resetValidation());
    }

    /**
     *  Restores all fields to its initial value
     *  $emit('input') triggers v-model / value prop
     */
    public restore(): void {
        this.fields.forEach((field) => field.$emit('input', field.initialValue));
    }

    public reset(): void {
        const form = this.$el as HTMLFormElement;
        form.reset();
    }
}
