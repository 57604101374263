import _Vue from 'vue';

import NotificationComponent from '../notification.component.vue';
import notificationPluginEventhub from './notification-eventhub.plugin';

export function NotificationPlugin(Vue: typeof _Vue, options?: any): void {
    Vue.component('notification-component', NotificationComponent);

    Vue.prototype.$notify = (message: string) => {
        notificationPluginEventhub.$emit('message', message);
    };
}
