// import { GlobalStore } from '@/store/modules/global.store';
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export interface IRootState {
    isScrolling: boolean;
    isRouting: boolean;
}

const store = new Vuex.Store<IRootState>({
    state: {
        isScrolling: false,
        isRouting: false,
    },
    mutations: {
        isScrolling: (state, isScrolling: boolean) => (state.isScrolling = isScrolling),
        isRouting: (state, isRouting: boolean) => (state.isRouting = isRouting)
    },
    getters: {
        isRouting: (state) => state.isRouting
    },
    modules: {
        // GlobalStore,
    },
    plugins: [],
});

export default store;
