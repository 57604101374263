import Vue from 'vue';
import Component from 'vue-class-component';
import notificationPluginEventhub from './notification-plugin/notification-eventhub.plugin';

interface INotification {
    id: number;
    title: string;
    timer: any;
}

@Component
export default class NotificationComponent extends Vue {
    private notifications: INotification[] = [];

    private clearTime = 5000;

    protected mounted() {
        notificationPluginEventhub.$on('message', (message: string) => this.onAddNotification(message));
    }

    private onAddNotification(message: string) {

        // Generate a index so we can set keys for vue
        const lastNotification = this.notifications[this.notifications.length - 1];
        const id = (this.notifications.length) ? lastNotification.id + 1 : 0;

        const notification: INotification = {
            id,
            title: message,
            timer: setTimeout(() => {
                // Removes itself after x seconds
                this.removeNotificationHelper(notification);
            }, this.clearTime)
        };

        this.notifications.push(notification);
    }

    private removeNotificationHelper(notification: INotification) {
        this.notifications = this.notifications.filter(item => item.id !== notification.id);
    }

    private onMouseOver(notification: INotification) {
        clearTimeout(notification.timer);
    }

    private onMouseLeave(notification: INotification) {
        notification.timer = setTimeout(() => this.removeNotificationHelper(notification), this.clearTime);
    }

    private onCloseClick(notification: INotification) {
        this.removeNotificationHelper(notification);
    }
}