import { Component, Vue } from 'vue-property-decorator';
import HamburgermenuComponent from '../hamburgermenu/hamburgermenu.component';

// @Component
// export default class NavigationComponent extends Vue {
//     private onNavClick(className: any | string) {
//         // window.scroll({
//         //     behavior: 'smooth',
//         //     left: 0,
//         //     top: document.querySelector(className).offsetTop - 90,
//         // });
//     }

// }

@Component({
    components: {
        HamburgermenuComponent
    }
})
export default class NavigationComponent extends Vue {
    
    private isOpen: boolean = false;
    private isFixed: boolean = false;

    protected created() {
        this.$nextTick(() => this.calculateFixed());
    }

    private calculateFixed(): void {        
        window.onscroll = () => {            
            this.isFixed = window.scrollY > 0;
        };
    }

    private onMenuClick(isOpen: boolean): void {        
        this.isOpen = isOpen;
    }

    private onNavClick() {
        this.$emit('onMenuClick', false);
    }

}