import Vue from 'vue';

// import CheckboxComponent from '@/components/fields/checkbox/checkbox.component';
// import FormComponent from '@/components/fields/form/form.component';
// import InputComponent from '@/components/fields/input/input.component';
// import SelectlistComponent from '@/components/fields/selectlist/selectlist.component';

// Vue.component('form-component', FormComponent);
// Vue.component('input-component', InputComponent);
// Vue.component('selectlist-component', SelectlistComponent);
// Vue.component('checkbox-component', CheckboxComponent);

import { NotificationPlugin } from '@/components/notification/notification-plugin/notification.plugin';
Vue.use(NotificationPlugin);
