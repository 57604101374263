import { render, staticRenderFns } from "./parallax.component.html?vue&type=template&id=297d9a90&scoped=true&"
import script from "./parallax.component.ts?vue&type=script&lang=ts&"
export * from "./parallax.component.ts?vue&type=script&lang=ts&"
import style0 from "./parallax.component.scss?vue&type=style&index=0&id=297d9a90&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "297d9a90",
  null
  
)

export default component.exports