import Vue from 'vue';

Vue.directive('click-outside', {
    bind(el: any, binding: any, vnode: any) {
        el.clickOutsideEvent = (event: any) => {
            // here I check that click was outside the el and his childrens
            if (!(el === event.target || el.contains(event.target))) {
                // and if it did, call method provided in attribute value
                vnode.context[binding.expression](event);
            }
        };
        document.body.addEventListener('click', el.clickOutsideEvent);
    },
    unbind(el) {
        document.body.removeEventListener('click', (el as any).clickOutsideEvent);
    },
});

Vue.directive('scroll', {
    inserted(el, binding) {
        const f = (evt: any) => {
            if (binding.value(evt, el)) {
                window.removeEventListener('scroll', f);
            }
        };
        window.addEventListener('scroll', f);
    },
});
