import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

declare namespace google.maps {
    const LatLng: any;
    const Marker: any;
    const Map: any;
}

export interface IGoogleMapLocation {
    LATITUDE: number;
    LONGITUDE: number;
}

@Component
export default class GooglemapComponent extends Vue {

    @Prop() private location!: IGoogleMapLocation;
    @Prop() private markerTitle!: string;

    protected mounted() {
        this.createMap();
    }

    private createMap() {
        // For more options see: https://developers.google.com/maps/documentation/javascript/reference#MapOptions
        const mapOptions: any = {
            zoom: 13,
            center: new google.maps.LatLng(this.location.LATITUDE, this.location.LONGITUDE),
            disableDefaultUI: true, // Disable default UI
            scrollwheel: false, // Disable scrolling
        };

        // Create the Google Map using our element and options defined above
        const map = new google.maps.Map(this.$el, mapOptions);

        const marker = new google.maps.Marker({
            position: new google.maps.LatLng(this.location.LATITUDE, this.location.LONGITUDE),
            map,
            title: this.markerTitle,
        });
    }
}
